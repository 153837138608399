import React from 'react';
import { Link } from 'react-router-dom'

import Celular from '../assets/celular.svg'
import Sofa from '../assets/sofa.svg'
import Roupa from '../assets/roupa.svg'
import Carro from '../assets/carro.svg'
import Alter from '../assets/alter.svg'
import Ticket from '../assets/ticket.svg'
import Tv from '../assets/tv.svg'
import Pincel from '../assets/pincel.svg'

// import Slide from '../template/slide'

import Grid from '../template/grid'

export default props => (
    <div className="container landing_back_escuro">
        <div className="content">
            <div className="landing_block_modalidades">
                <div>
                    <h1>Principais categorias:</h1>
                </div>
                <Grid cols='12 12 12'>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/smartphones/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Celular} />
                                <p>Smartphones</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/moveis/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Sofa} />
                                <p>Móveis</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/vestuarios/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Roupa} />
                                <p>Vestuários</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/mobilidade/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Carro} />
                                <p>Mobilidade</p>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
                <Grid cols='12 12 12'>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/esportes/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Alter} />
                                <p>Esportes</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/entretenimento/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Ticket} />
                                <p>Entretenimento</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/eletro-eletronicos/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Tv} />
                                <p>Eletro-eletrônicos</p>
                            </div>
                        </Link>
                    </Grid>
                    <Grid cols='6 3 3'>
                        <Link to={'/anuncios/infantil/'}>
                            <div className="landing_modalidades">
                                <img title="" alt="" src={Pincel} />
                                <p>Infantil</p>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </div>
        {/* <Slide /> */}
    </div>
)