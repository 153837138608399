import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Button, Modal } from 'react-bootstrap'
import InputMask from 'react-input-mask';

import Loading from '../template/loading'

export const Perfil = props => {

    const loader = async () => {
        const data = await props.user.data
        try {
            changeCep(data.user_metadata.cep)
            changePhone(data.user_metadata.phone)
            changeEstado(data.user_metadata.federated_unit)
            changeCidade(data.user_metadata.city)
            return data.user_metadata.cep
        } catch (e) {
            return ''
        }
    }

    useState(loader)

    const [cep, changeCep] = useState();
    const [phone, changePhone] = useState();
    const [estado, changeEstado] = useState();
    const [cidade, changeCidade] = useState();

    const buscarCEP = (cep, evt) => {
        const cepGoal = (evt.target.validity.valid) ? evt.target.value : cep;
        changeCep(cepGoal)

        if (cep.length < 8) return;
        let url = `https://viacep.com.br/ws/${cep}/json/`;
        fetch(url)
            .then((res) => {
                if (res.ok) {
                    res.json().then((json) => {
                        if (!json.erro) {
                            changeCidade(json.localidade);
                            changeEstado(json.uf);
                            changeCep(cep)
                        } else {
                            alert('Insira um CEP válido')
                        }
                    });
                }
            });
    }

    const user = props.user;

    const noneFunction = () => {

    }

    const phoneChange = (evt) => {
        const phoneGoal = (evt.target.validity.valid) ? evt.target.value : phone;
        return changePhone(phoneGoal)
    }

    const stopRefresh = (event) => {
        event.preventDefault();
        handleShow()
    }

    const updateData = async () => {

        const data = JSON.stringify({
            "user_metadata": {
                "cep": cep,
                "city": cidade,
                "federated_unit": estado,
                "phone": phone,
            }
        })
        await axios({
            url: 'https://tocomprando.auth0.com/oauth/token',
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            data: "{\"client_id\":\"QrFMwN1MLOlkFjxXejbg790E0z1aPUM4\",\"client_secret\":\"S6vz00iM6LIxEiFJlQcuSafpn4DJHOVMETI83q4TadUEOCIywqpErZbAWY1B2TDB\",\"audience\":\"https://tocomprando.auth0.com/api/v2/\",\"grant_type\":\"client_credentials\"}"
        }).then(async (client_credentials) => {
            await axios({
                url: `https://tocomprando.auth0.com/api/v2/users/${user.data.user_id}`,
                method: 'PATCH',
                headers: { 'Content-type': 'application/json', 'authorization': `${client_credentials.data.token_type} ${client_credentials.data.access_token}` }
                , data: data
            }).then((result) => {
                console.log(result.data)
                refresh()
            });
        });
        //return author
    }

    const refresh = () => {
        window.location.reload()
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loadings, changeLoading] = useState(true)

    const demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 3000));
    }

    // console.log(user)
    useEffect(() => {
        demoAsyncCall().then(() => changeLoading(false));
    })


    if (loadings || !user) {
        return (
            <div className='container-loading text-center'>
                <Loading />
            </div>
        )
    } else {
        return (
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                {/* Modal */}
                <Modal size="md" show={show} onHide={handleClose} aria-labelledby="ModalHeader" style={{ display: "block", opacity: 1 }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Atualizar dados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Seus dados serão atualizados. Deseja prosseguir?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Não
                    </Button>
                        <Button variant="primary" onClick={updateData}>
                            Sim
                    </Button>
                    </Modal.Footer>
                </Modal>
                <h1>Dados Pessoais</h1>
                <form onSubmit={stopRefresh}>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 mt20'>
                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="name">Nome</label>
                            <input type="text" name="name" className="form-control form-post" value={user.data.name} required="required" onChange={() => noneFunction()} />
                        </div>
                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="email">Mail</label>
                            <input type="text" name="email" className="form-control form-post" value={user.data.email} required="required" onChange={() => noneFunction()} />
                        </div>
                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="telefone">Telefone</label>
                            {/* <input type="number" name="telefone" className="form-control form-post numb" value={phone} required="required" title="" placeholder='Insira seu telefone! ex: 11999887766' onChange={(e) => phoneChange(e)} /> */}
                            <InputMask mask="(99)99999-9999" name="telefone" className="form-control form-post numb" value={phone} required="required" title="" placeholder='Insira seu telefone!' minLength="10" onChange={(e) => phoneChange(e)} />
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 mt20'>
                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="cep">CEP</label>
                            <InputMask mask="99999-999" name="cep" id='cep' className="form-control form-post" required="required" value={cep} placeholder="Digite o CEP" minLength="8" onChange={(e) => buscarCEP(e.target.value, e)} />
                            {/* <input type="text" name="cep" id='cep' className="form-control form-post" required="required" value={cep} placeholder="Digite o CEP (apenas números)" minLength="8" maxLength="8" onChange={(e) => buscarCEP(e.target.value, e)} /> */}
                        </div>

                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="cidade">Cidade</label>
                            <input type="text" name="cidade" id="cidade" value={cidade} className="form-control form-post" required="required" placeholder="Cidade (insira o CEP)" onChange={(e) => changeCidade(e)} />
                        </div>

                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="estado">Estado</label>
                            <input type="text" name="estado" id="estado" value={estado} className="form-control form-post" required="required" placeholder="Estado (insira o CEP)" onChange={(e) => changeEstado(e)} />
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 mt20'>
                        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                            <label htmlFor="verificado">Conta verificada</label>
                            <input type="text" name="verificado" id="verificado" value={user.data.email_verified === true ? 'Sim' : 'Não'} className="form-control form-post" required="required" onChange={() => noneFunction()} />
                        </div>
                        <div className="postar_btn col-xs-12 col-md-12 col-sm-12 col-lg-12 mt20">
                            <button type='submit' className="btn bota">ATUALIZAR DADOS</button>
                        </div>
                    </div>
                </form>

            </div>
        )
    }
}

export default Perfil