import React from 'react';

export const DetalhesHeader = props => {
    return (
        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
            <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                <h1>{props.title}</h1>
            </div>
            <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                <h1>
                    <b className='b-fino'>Valor oferecido:</b>
                    <br/>
                    <b className='super-text'> R$ {props.price}</b>
                </h1>
            </div>
        </div>
    )
}

export default DetalhesHeader