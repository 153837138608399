import axios from "axios";

export const getCategoryName = (category) => {
  switch (category) {
    case "telephony":
      return "Smartphones";
    case "furniture":
      return "Móveis";
    case "clothing":
      return "Vestuário";
    case "mobility":
      return "Mobilidade";
    case "sports":
      return "Esportes";
    case "entertainment":
      return "Entretenimento";
    case "consumer-electronics":
      return "Eletro-eletrônicos";
    case "children":
      return "Infantil";
    case "other":
      return "Outros";
    default:
      return 0;
  }
};

export const getCategory = (category) => {
  switch (category) {
    case "telephony":
      return "smartphones";
    case "furniture":
      return "moveis";
    case "clothing":
      return "vestuario";
    case "mobility":
      return "mobilidade";
    case "sports":
      return "esportes";
    case "entertainment":
      return "entretenimento";
    case "consumer-electronics":
      return "eletro-eletronicos";
    case "children":
      return "infantil";
    case "other":
      return "outros";
    default:
      return 0;
  }
};

export const setAllAuthors = async () => {
  // Gerar credenciais Auth0
  const token = await axios({
    url: "https://tocomprando.auth0.com/oauth/token",
    method: "POST",
    headers: { "Content-type": "application/json" },
    data:
      '{"client_id":"QrFMwN1MLOlkFjxXejbg790E0z1aPUM4","client_secret":"S6vz00iM6LIxEiFJlQcuSafpn4DJHOVMETI83q4TadUEOCIywqpErZbAWY1B2TDB","audience":"https://tocomprando.auth0.com/api/v2/","grant_type":"client_credentials"}',
  }).then(async (client_credentials) => {
    // console.log(client_credentials.data)
    const author = await axios({
      url: "https://tocomprando.auth0.com/api/v2/users?email_verified:true",
      method: "GET",
      headers: {
        "Content-type": "application/json",
        authorization: `${client_credentials.data.token_type} ${client_credentials.data.access_token}`,
      },
    }).then((result) => {
      return result;
    });
    return author;
  });
  return token;
};

export const removeDemand = async (id) => {
  const data = {
    query: `
    mutation Remove {
      delete_demand(where: {id: {_eq: "${id}"}}) {
        affected_rows
      }
    }
    `,
  };
  await axios({
    url: "https://tocomprando-api.herokuapp.com/v1/graphql",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "X-hasura-admin-secret": "to-vendendo-a-rupi",
    },
    data: data,
  })
    .then((result) => {
      //console.log(result.data.data);
      window.location.reload();
    })
    .catch((err) => alert("Erro: ", err));
};
