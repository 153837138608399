import React from 'react';
import { Link } from 'react-router-dom'
import { useAuth0 } from "../auth0/react-auth0-wrapper";

import tocomprandoLogo from '../assets/logoToComprando_header.svg'

const NavBar = () => {
    const { user, isAuthenticated, loginWithRedirect, /*loginWithPopup,*/ logout } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    return (
        <nav className="navbar navbar-fixed-top landing_back_header">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <Link className='navbar-brand bota' to='/'>TôComprando</Link>
                    <img src={tocomprandoLogo} alt="" />
                </div>

                <div className="collapse navbar-collapse" id="bs-collapse-1">
                    <ul className="nav navbar-nav navbar-right">
                        {isAuthenticated && (
                            <React.Fragment>
                                <li className="dropdown">
                                    <button className="btn btn-xs button-blind dropdown-toggle" type="button" data-toggle="dropdown">
                                        <Link to="/perfil">
                                            <b className='navbar-brand'>
                                                Olá, {user.name}
                                            </b>
                                        </Link>
                                        <span className="caret"></span>
                                    </button>

                                    <ul className="dropdown-menu">

                                        <li>
                                            <Link className='bota' to="/perfil">
                                                <b className='bota'>Perfil</b>
                                            </Link>
                                        </li>

                                        <li>
                                            {isAuthenticated && <a href="/" className='bota' onClick={() => logoutWithRedirect()}>
                                                <b className='bota'>Log out</b>
                                            </a>}
                                        </li>

                                    </ul>
                                </li>
                            </React.Fragment>
                        )}
                        {!isAuthenticated && (
                            // <button className='button-blind' onClick={() => loginWithPopup()}> 
                            <button className='button-blind' onClick={() => loginWithRedirect({})}> 
                            <b className='navbar-brand bota'> Login / cadastro</b>
                            </button>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar