import React, {useState, useEffect } from 'react';

import { CarouselDetails } from '../template/carousel'

import Icon_smartphones from '../assets/icon_smartphones.svg'
import Icon_eletronico from '../assets/icon_eletronico.svg'
import Icon_veiculos from '../assets/icon_veiculos.svg'
import Icon_criancas from '../assets/icon_bebes-criancas.svg'
import Icon_vestuario from '../assets/icon_vestuario.svg'
import Icon_moveis from '../assets/icon_moveis.svg'
import Icon_esportes from '../assets/icon_esportes.svg'
import Icon_entretenimento from '../assets/icon_entretenimento.svg'
import Icon_relatorios from '../assets/icon_relatorios.svg'

export const DetalhesBody = props => {
    
    let [imageIcon, setIcon] = useState()

    const loadIcon = () => {
        switch (props.category) {
            case 'Smartphones':
                setIcon(Icon_smartphones);
                break;
            case 'Eletro-eletrônicos':
                setIcon(Icon_eletronico);
                break;
            case 'Mobilidade':
                setIcon(Icon_veiculos);
                break;
            case 'Infantil':
                setIcon(Icon_criancas);
                break;
            case 'Vestuário':
                setIcon(Icon_vestuario);
                break;
            case 'Móveis':
                setIcon(Icon_moveis);
                break;
            case 'Esportes':
                setIcon(Icon_esportes);
                break;
            case 'Entretenimento':
                setIcon(Icon_entretenimento);
                break;
            case 'Outros':
                setIcon(Icon_relatorios);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        loadIcon()
    })

    return (
        <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                    <div className='box-450 col-xs-12 col-md-6 col-sm-6 col-lg-6 mt50'>
                        <CarouselDetails items={props.images} />
                        <div className='icone_card'>
                            <img alt="" title="" src={imageIcon} />
                        </div>
                    </div>
                    <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                        <div className="jumbotron">
                            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                                <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                    <label>Comprador</label>
                                    <h3>{props.author}</h3>
                                </div>
                                <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                    <label>Localização</label>
                                    <h3>{props.city}, {props.state}</h3>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 mt20'>
                                <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                    <label>Categoria</label>
                                    <h3>{props.category}</h3>
                                </div>
                                <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                    <label>Data da oferta</label>
                                    <h3>{props.date}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="jumbotron-2">
                            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                                <label>Sobre o produto</label>
                            </div>
                            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 mt20 text-justify'>
                            {props.description}
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default DetalhesBody