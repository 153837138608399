import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Icon_smartphones from "../assets/icon_smartphones.svg";
import Icon_eletronico from "../assets/icon_eletronico.svg";
import Icon_veiculos from "../assets/icon_veiculos.svg";
import Icon_criancas from "../assets/icon_bebes-criancas.svg";
import Icon_vestuario from "../assets/icon_vestuario.svg";
import Icon_moveis from "../assets/icon_moveis.svg";
import Icon_esportes from "../assets/icon_esportes.svg";
import Icon_entretenimento from "../assets/icon_entretenimento.svg";
import Icon_relatorios from "../assets/icon_relatorios.svg";

import { getCategory } from "../services";

export const Card = props => {
  // console.log('Props dos card: ',props)
  let [imageIcon, setIcon] = useState();

  const goTo = {
    pathname: `/anuncios/${props.uri}/detalhes/${props.id}`,
    param: props
  };

  const loadCard = () => {
    // console.log('Card props: ', props)
    switch (props.categoria) {
      case "Smartphones":
        setIcon(Icon_smartphones);
        break;
      case "Eletro-eletrônicos":
        setIcon(Icon_eletronico);
        break;
      case "Mobilidade":
        setIcon(Icon_veiculos);
        break;
      case "Infantil":
        setIcon(Icon_criancas);
        break;
      case "Vestuário":
        setIcon(Icon_vestuario);
        break;
      case "Móveis":
        setIcon(Icon_moveis);
        break;
      case "Esportes":
        setIcon(Icon_esportes);
        break;
      case "Entretenimento":
        setIcon(Icon_entretenimento);
        break;
      case "Outros":
        setIcon(Icon_relatorios);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadCard();
  });

  return (
    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
      <div className="caixas">
        <div className="icone_card">
          <img alt="" title="" src={imageIcon} />
        </div>
        <div className="caixaroxa">
          <h1>{props.title}</h1>
        </div>
        <div className="caixacinza">
          <div className="first_caixa">
            <b>Valor oferecido</b>
            <span>R$ {props.price}</span>
          </div>
          <div className="minicaixa">
            <b>Comprador</b>
            <p>{props.author}</p>
          </div>
          <div className="minicaixa">
            <b>Localização</b>
            <p>
              {props.city}, {props.state}
            </p>
          </div>
          <div className="minicaixa">
            <b>Categoria</b>
            <p>{props.categoria}</p>
          </div>
          <div className="minicaixa">
            <b>Data da oferta</b>
            <p>{props.date}</p>
          </div>
        </div>
      </div>
      <div className="landing_details">
        <Link to={goTo}>
          <button className="bota">DETALHES</button>
        </Link>
      </div>
    </div>
  );
};

export const CardDetails = props => {
  // console.log('Props dos card: ',props)
  let [imageIcon, setIcon] = useState();

  const redirect = target => {
    const categoria = getCategory(props.category_tag);
    window.location.href = `/anuncios/${categoria}/detalhes/${props.id}`;
  };

  const loadCard = () => {
    // // console.log('Card props: ', props)
    switch (props.categoria) {
      case "Smartphones":
        setIcon(Icon_smartphones);
        break;
      case "Eletro-eletrônicos":
        setIcon(Icon_eletronico);
        break;
      case "Mobilidade":
        setIcon(Icon_veiculos);
        break;
      case "Infantil":
        setIcon(Icon_criancas);
        break;
      case "Vestuário":
        setIcon(Icon_vestuario);
        break;
      case "Móveis":
        setIcon(Icon_moveis);
        break;
      case "Esportes":
        setIcon(Icon_esportes);
        break;
      case "Entretenimento":
        setIcon(Icon_entretenimento);
        break;
      case "Outros":
        setIcon(Icon_relatorios);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadCard();
  });

  return (
    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
      <div className="caixas">
        <div className="icone_card">
          <img alt="" title="" src={imageIcon} />
        </div>
        <div className="caixaroxa">
          <h1>{props.title}</h1>
        </div>
        <div className="caixacinza">
          <div className="first_caixa">
            <b>Valor oferecido</b>
            <span>R$ {props.price}</span>
          </div>
          <div className="minicaixa">
            <b>Comprador</b>
            <p>{props.author}</p>
          </div>
          <div className="minicaixa">
            <b>Localização</b>
            <p>
              {props.city}, {props.state}
            </p>
          </div>
          <div className="minicaixa">
            <b>Categoria</b>
            <p>{props.categoria}</p>
          </div>
          <div className="minicaixa">
            <b>Data da oferta</b>
            <p>{props.date}</p>
          </div>
        </div>
      </div>
      <div className="landing_details">
        <button className="bota" onClick={e => redirect()}>
          DETALHES
        </button>
      </div>
    </div>
  );
};

export default Card;
