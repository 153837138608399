import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth0 } from "../auth0/react-auth0-wrapper";

// import $ from 'jquery'

import arrowDOWN from '../assets/arrowDown.svg'
import maisVendido from '../assets/mais_vendido.svg'

export const Credits = props => {
   // console.log(props.match.params.sub)
   const { user } = useAuth0();

   const pagSeguro = async (tag) => {
      // Gerar credenciais Auth0
      const token = await axios({
         url: 'https://tocomprando.auth0.com/oauth/token',
         method: 'POST',
         headers: { 'Content-type': 'application/json' },
         data: "{\"client_id\":\"QrFMwN1MLOlkFjxXejbg790E0z1aPUM4\",\"client_secret\":\"S6vz00iM6LIxEiFJlQcuSafpn4DJHOVMETI83q4TadUEOCIywqpErZbAWY1B2TDB\",\"audience\":\"https://tocomprando.auth0.com/api/v2/\",\"grant_type\":\"client_credentials\"}"
      }).then(async (client_credentials) => {
         // console.log(client_credentials.data)
         const author = await axios({
            url: `https://tocomprando.auth0.com/api/v2/users/${user.sub}`,
            method: 'GET',
            headers: { 'Content-type': 'application/json', 'authorization': `${client_credentials.data.token_type} ${client_credentials.data.access_token}` }
         }).then(async (result) => {
            // console.log(result)
            const credentials = {
               appID: 'app9796492589',
               AppKey: '92F849CD8282C5A9948E5F9F6853AADB',
               email: 'v42586402290277634838@sandbox.pagseguro.com.br',
               pswd: 'L91yPbx972NkdEek',
               public: 'PUBB69A771DD35F4EB4922FDF1290EA4CCA',
               mail: 'rafael.andrade400@gmail.com',
               token: '708ECC3E45EA4C0D9B5679E03B72281E'
            }
            // var xmlhttp = new XMLHttpRequest();
            // Sandbox
            /*xmlhttp.open("POST", `https://ws.sandbox.pagseguro.uol.com.br/v2/checkout?appID=${credentials.appID}&appKey=${credentials.AppKey}`);
            var xmlDoc;
            xmlhttp.onreadystatechange = function () {
               if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                  xmlDoc = xmlhttp.responseXML;
                  console.log(xmlDoc);
               }
            };
            xmlhttp.setRequestHeader('Content-Type', 'application/xml; charset=ISO-8859-1')
            xmlhttp.setRequestHeader('Access-Control-Allow-Origin', 'https://dev.pagseguro.uol.com.br/')
            */
            var xml = `<?xml version="1.0"?>
            <checkout>
              <sender>
                <name>Jose Comprador</name>
                <email>comprador@uol.com.br</email>
                <phone>
                  <areaCode>99</areaCode>
                  <number>999999999</number>
                </phone>
                <documents>
                  <document>
                    <type>CPF</type>
                    <value>11475714734</value>
                  </document>
                </documents>
              </sender>
              <currency>BRL</currency>
              <items>
                <item>
                  <id>0001</id>
                  <description>Produto PagSeguroI</description>
                  <amount>99999.99</amount>
                  <quantity>1</quantity>
                  <weight>10</weight>
                  <shippingCost>1.00</shippingCost>
                </item>
              </items>
              <redirectURL>http://lojamodelo.com.br/return.html</redirectURL>
              <extraAmount>10.00</extraAmount>
              <reference>REF1234</reference>
              <shipping>
                <address>
                  <street>Av. PagSeguro</street>
                  <number>9999</number>
                  <complement>99o andar</complement>
                  <district>Jardim Internet</district>
                  <city>Cidade Exemplo</city>
                  <state>SP</state>
                  <country>BRA</country>
                  <postalCode>99999999</postalCode>
                </address>
                <type>1</type>
                <cost>1.00</cost>
                <addressRequired>true</addressRequired>
              </shipping>
              <timeout>25</timeout>
              <maxAge>999999999</maxAge>
              <maxUses>999</maxUses>
              <receiver>
                <email>suporte@lojamodelo.com.br</email>
              </receiver>
              <enableRecovery>false</enableRecovery>
            </checkout>`
            // xmlhttp.send(xml);

            const ress = await axios({
               url: `https://ws.sandbox.pagseguro.uol.com.br/v2/checkout?appID=${credentials.appID}&appKey=${credentials.AppKey}`,
               method: 'POST',
               headers: { 'Content-type': 'application/xml; charset=ISO-8859-1' },
               data: xml
            }).then((result) => {
               console.log(result)
               return result
            });
            return ress
         });
         return author
      });
      return token
   }

   useState()
   // const [getAuthorList] = useState(pagSeguro)

   useEffect(() => {

   })

   return (
      <div>
         <div className='mt50'></div>
         <div className="container">
            <div className="content">
               <div className="landing_comprando" id='t'>
                  <h1>Escolha seu pacote de créditos</h1>
               </div>
               <article className='text-justify content'>
                  <p>Para negociar com compradores, você precisa adquirir um de nossos planos. É muito fácil: Você compra pacotes de créditos, que vai utilizando à medida que for necessário para fechar suas vendas. Em todos os planos sugeridos, você pode ter acesso ao comprador e tirar todas as dúvidas. Você gastará efetivamente os créditos ao fazer contato direto com um comprador.</p>
               </article>
               <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt50 mb50'>
                  <div className='hidden-xs hidden-sm col-md-1 col-lg-1'></div>

                  <div className='col-xs-6 col-sm-12 col-md-2 col-lg-2 text-center'>
                     <div className='square-2'> <b>3</b> <p>OFERTAS</p> </div>
                     <div className='text-center'>
                        <img className='seta-down-mini' title="" alt="" id='' src={arrowDOWN} />
                     </div>
                     <div className='losang-2'>GRÁTIS</div>
                  </div>
                  <div className='col-xs-6 col-sm-12 col-md-2 col-lg-2 text-center'>
                     <div className='square-2'> <b>5</b> <p>OFERTAS</p> </div>
                     <div className='text-center'>
                        <img className='seta-down-mini' title="" alt="" id='' src={arrowDOWN} />
                     </div>
                     <button className='losang-2' onClick={() => pagSeguro(5)}>
                        R$ 5
                     </button>
                  </div>
                  <div className='col-xs-6 col-sm-6 col-md-2 col-lg-2 text-center'>
                     <div className='square-2'> <b>10</b> <p>OFERTAS</p> </div>
                     <div className='text-center'>
                        <img className='seta-down-mini' title="" alt="" id='' src={arrowDOWN} />
                     </div>
                     <button className='losang-2' onClick={() => pagSeguro(7)}>
                        R$ 7
                     </button>
                  </div>
                  <div className='col-xs-6 col-sm-6 col-md-2 col-lg-2 text-center'>
                     <div className='square-2'> <b>20</b> <p>OFERTAS</p> </div>
                     <div className='text-center'>
                        <img className='seta-down-mini' title="" alt="" id='' src={arrowDOWN} />
                     </div>
                     <button className='losang-2' onClick={() => pagSeguro(15)}>
                        R$ 15
                     </button>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-2 col-lg-2 text-center mais-vendido mt20'>
                     <img className='pull-right' src={maisVendido} alt="" />
                     <div className='square-2'> <b>50</b> <p>OFERTAS</p> </div>
                     <div className='text-center'>
                        <img className='seta-down-mini' title="" alt="" id='' src={arrowDOWN} />
                     </div>
                     <button className='losang-2' onClick={() => pagSeguro(40)}>
                        R$ 40
                     </button>
                  </div>

                  <div className='hidden-xs hidden-sm col-md-1 col-lg-1'></div>
               </div>
            </div>
         </div>
         <div className='mt50 mb50'></div>
      </div>
   )
}

export default Credits