import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import Lettering from '../assets/logoToComprando_Lettering.svg'
import Icon_busca from '../assets/icon_busca.svg'

export const SearchBar = props => {

    const [title, changeTitle] = useState('')
    const [price, changePrice] = useState('')

    const goTo = {
        pathname: '/perfil',
        param: { price, title }
    }

    const valueChange = (evt) => {
        const financialGoal = (evt.target.validity.valid) ? evt.target.value : price;
        return changePrice(financialGoal)
    }

    useEffect(() => {
        window.sessionStorage.setItem('title', title)
        window.sessionStorage.setItem('price', price)
    })

    return (
        <div className="landing_comprando">
            <div className="content">
                <img title="" alt="" src={Lettering} />
                <div className='hidden-xs hidden-sm'>
                    <h1>O que você quer comprar e <p>quanto está disposto a pagar?</p></h1>
                </div>

                <div className='hidden-md hidden-lg'>
                    <h1>O que você quer comprar e quanto está disposto a pagar?</h1>
                </div>
                <p>Uma nova forma de fazer compras, onde você valoriza seu dinheiro e só encontra vendedores que aceitem sua oferta.</p>

                <form>
                    <input className="form" type="text" placeholder="ex: Compro iPhone 8 Plus 64GB" required='required' onChange={(e) => changeTitle(e.target.value)} />
                    <input pattern="[0-9]*" type='number' className="form" required="required" placeholder="Quanto quer pagar (R$)?" onChange={(e) => valueChange(e)} />
                    {(title !== '' && price !== '') && (
                        <React.Fragment>
                            <Link to={goTo}>
                                <div className='hidden-lg hidden-sm hidden-md text-center'>
                                    <button type='submit' className="bota">POSTAR</button>
                                </div>
                            </Link>
                            <Link to={goTo}>
                                <button className="bota hidden-xs">POSTAR</button>
                            </Link>
                        </React.Fragment>
                    )}
                </form>
            </div>
        </div>
    )
}

export const SearchBar2 = props => {
    const [title, changeTitle] = useState('')

    useEffect(() => {
        window.sessionStorage.setItem('title', title)
    })

    return (
        <form>
            <input className='form' type="text" placeholder="Busque o que estão comprando" value={title} onChange={(e) => changeTitle(e.target.value)} required='required' />
            {title && (
                <Link to='anuncios/todos'>
                    <img title="" alt="" src={Icon_busca} />
                </Link>
            )}
            {!title && (
                <a href="true" className='bota'><img title="" alt="" src={Icon_busca} /></a>
                // eslint-disable-next-line
            )}
        </form >
    )
}

export default SearchBar