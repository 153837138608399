import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Loading from '../template/loading'

export const DetalhesTable = props => {

    // console.log(props)
    const [loading, changeLoading] = useState(true)

    const [infos, changeInfos] = useState('')

    const getToVendendoUsers = async () => {
        const data = {
            query: `{
                        demand_by_pk(id: "${props.id}") {
                            offers(order_by: {created_at: asc}) {
                                id
                                seller_id
                                created_at
                            }
                        }
                    }
                    `
        }
        await axios({
            url: ' https://tocomprando-api.herokuapp.com/v1/graphql',
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
            data: data
        }).then((result) => {
            // console.log('Res: ', result.data.data.demand_by_pk.offers)

            result.data.data.demand_by_pk.offers.map(async res => {
                const data_old = res.created_at.substring(0, 10).split('-')
                res.created_date = data_old[2] + '/' + data_old[1] + '/' + data_old[0]
                const tmp = getAuthorInfos(res.seller_id)
                res.author = await tmp;
            })
            changeInfos(result.data.data.demand_by_pk.offers)
        })
    }
    useState(getToVendendoUsers)

    const getAuthorInfos = async (author_id) => {
        // console.log('author_id: ', author_id)
        let result = await props.authors.then(resp => {
            // console.log('resp: ', resp)
            let resultado

            resp.data.map(prod => {
                if (prod.user_id === author_id) {
                    return resultado = prod
                }
                return resultado
            })
            // console.log('resultado: ', resultado)
            return resultado
        })
        return result
    }

    useEffect(() => {
        // console.log(infos)
    })

    const demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 3000));
    }

    useEffect(() => {
        demoAsyncCall().then(() => changeLoading(false));
        // console.log(infos[0])
    })

    if (loading) {
        return (
            <div className='container-loading text-center'>
                <Loading />
            </div>
        )
    } else {
        return (
            <div className='table-jumbotron col-xs-12 col-md-12 col-sm-12 col-lg-12 content'>
                <Table className='table table-hover table-status table-profile'>
                    <Thead>
                        <Tr>
                            <Th><label htmlFor="">Nome</label></Th>
                            <Th><label htmlFor="">Contato</label></Th>
                            <Th><label htmlFor="">Data de Interesse</label></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {infos.map(result => (
                            <Tr key={result.id}>
                                <Td>
                                    <b>{result.author.name}</b>
                                </Td>
                                <Td>
                                    <b>{result.author.user_metadata.phone}</b>
                                </Td>
                                <Td>
                                    <b>{result.created_date}</b>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>
        )
    }
}

export default DetalhesTable