import React from 'react';

import buttonDOWN from '../assets/buttonDOWN.svg'

export const Carousel = props => {
    return (
        <div className='content'>
            <div id="carouseltocomprando" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouseltocomprando" data-slide-to="0" className="active"></li>
                    <li data-target="#carouseltocomprando" data-slide-to="1"></li>
                    {/* <li data-target="#carouseltocomprando" data-slide-to="2"></li> */}
                </ol>

                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <img src={props.img_1} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>
                    <div className="item">
                        <img src={props.img_2} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>
                    {/* <div className="item">
                    <img src={this.props.img_3} alt="..." />
                    <div className="carousel-caption">
                        ...
          </div>
                </div>
                <div className="item">
                    <img src={this.props.img_4} alt="..." />
                    <div className="carousel-caption">
                        ...
          </div>
                </div> */}

                </div>

                {/* <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a> */}
                <div className="landing_seta">
                    <img title="" alt="" id='fixa' src={buttonDOWN} />
                </div>
            </div>
        </div>
    )
}

export const CarouselDetails = props => {

    return (
        <React.Fragment>
            <div id="carouseldetails" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouseldetails" data-slide-to="0" className="active"></li>
                    {/* <li data-target="#carouseldetails" data-slide-to="1"></li>
                    <li data-target="#carouseldetails" data-slide-to="2"></li>
                    <li data-target="#carouseldetails" data-slide-to="3"></li> */}
                </ol>

                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <img src={props.items} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>
                    <div className="item">
                        <img src={props.items} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>
                    <div className="item">
                        <img src={props.items} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>
                    <div className="item">
                        <img src={props.items} alt="..." />
                        <div className="carousel-caption">

                        </div>
                    </div>

                </div>

                {/* <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span className="sr-only">Anterior</span>
                </a>
                <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span className="sr-only">Próxima</span>
                </a> */}
            </div>
        </React.Fragment>
    )
}

export default Carousel