import React from 'react';
import {Link} from 'react-router-dom'

import Alvo from '../assets/alvo.svg'
import Lupa from '../assets/lupa.svg'
import Correto from '../assets/correto.svg'
import Piscadinha from '../assets/piscadinha.svg'
import Sacola from '../assets/sacola.svg'
import Presente from '../assets/presente.svg'

export default props => (
    <div className="container landing_back_branco">
        <div className="content">
            <div className="landing_porque_comecar">
                <div className="landing_porque_comecar_pq">
                    <h1>Por que começar agora?</h1>
                    <p>Pense em algo que deseja comprar e poste aqui, já que agora que você tem o poder. Para isso, oferecemos uma plataforma cheia de vantagens para te ajudar nesse processo, de forma a te aproximar apenas das pessoas realmente interessadas em vender o que possuem.</p>
                </div>
                <div className="landing_responsive_tres">
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Alvo} />
                        <h1>Sistema de Matching</h1>
                        <p>Usamos tecnologia de ponta para que <br />você só receba propostas boas.</p>
                    </div>
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Lupa} />
                        <h1>Busca Otimizada</h1>
                        <p>Não vamos apenas listar, vamos ampliar o seu perfil para vendedores.</p>
                    </div>
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Correto} />
                        <h1>Autenticação de confiança</h1>
                        <p>Toda as informações são seguras e não serão comercializadas para outras partes.</p>
                    </div>
                </div>
                <div className="landing_responsive_tres2">
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Piscadinha} />
                        <h1>User <br /> Friendly</h1>
                        <p>Criamos caminhos intuitivos, fáceis e interessantes de você conseguir o que quer.</p>
                    </div>
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Sacola} />
                        <h1>Compras seguras</h1>
                        <p>Negocie diretamente com a pessoa que importa, usando nossos recursos.</p>
                    </div>
                    <div className="landing_pq_article">
                        <img title="" alt="" src={Presente} />
                        <h1>Recomendação de/para amigos</h1>
                        <p>Espalhe para toda a sua rede, afinal esse mundo é menor do que você imagina.</p>
                    </div>
                </div>
                <div className="buttton_comecar bota">
                    <Link to='/perfil'>
                        <button className='btn'>COMEÇAR AGORA</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>

)