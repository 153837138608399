import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useAuth0 } from "../auth0/react-auth0-wrapper";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import Loading from '../template/loading'

import $ from 'jquery'

import Post from './post'
import { ToComprando, ToVendendo } from './lists'
import MeusCreditos from './meusCreditos'
import Perfil from './perfil'

export const Profile = props => {

  const { user } = useAuth0();

  $(document).ready(() => {
    $("html, body").animate({ scrollTop: 0 }, 1000);
  })

  useEffect(() => {

  })

  const getAuthor = async () => {
    const author =
      await axios({
        url: 'https://tocomprando.auth0.com/oauth/token',
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: "{\"client_id\":\"QrFMwN1MLOlkFjxXejbg790E0z1aPUM4\",\"client_secret\":\"S6vz00iM6LIxEiFJlQcuSafpn4DJHOVMETI83q4TadUEOCIywqpErZbAWY1B2TDB\",\"audience\":\"https://tocomprando.auth0.com/api/v2/\",\"grant_type\":\"client_credentials\"}"
      }).then(async (client_credentials) => {
        await axios({
          url: `https://tocomprando.auth0.com/api/v2/users/${user.sub}`,
          method: 'GET',
          headers: { 'Content-type': 'application/json', 'authorization': `${client_credentials.data.token_type} ${client_credentials.data.access_token}` }
        }).then((result) => {
          // console.log(result.data)
          changeData(result)
          return result
        });
      });
    return author
  }

  useState(getAuthor)
  const [data, changeData] = useState()

  const [loadings, changeLoading] = useState(true)

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  }

  useEffect(() => {
    //  LOOP INFINITO
    //searchCards()
    /*categoryName.then((result) => {
        result.map(cat => (
            console.log('data: ', cat)
        ))
    })*/
    demoAsyncCall().then(() => changeLoading(false));

  })

  if (loadings || !user) {
    return (
      <div className='container-loading text-center'>
        <Loading />
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <div className="detalhes-page bg-grey">
          <div className="content">
            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 content topo'>
              <h1>Olá, {user.name}!</h1>
              <b>Mantenha seus dados sempre atualizados e fique atento a oportunidades!</b>
            </div>
            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 content'>
              <div className="jumbotron-profile">
                <Tabs>
                  <TabList>
                    <Tab>Novo Post</Tab>
                    <Tab>Tô Comprando</Tab>
                    <Tab>Tô Vendendo</Tab>
                    <Tab>Meus créditos</Tab>
                    <Tab>Perfil</Tab>
                  </TabList>

                  <TabPanel>
                    <Post href='post' user={data} />
                  </TabPanel>
                  <TabPanel>
                    <ToComprando user={data} />
                  </TabPanel>
                  <TabPanel>
                    <ToVendendo user={data} />
                  </TabPanel>
                  <TabPanel>
                    <MeusCreditos user={data} />
                  </TabPanel>
                  <TabPanel>
                    <Perfil user={data} />
                  </TabPanel>
                </Tabs>
              </div>

            </div>

            {/* <code>{JSON.stringify(user, null, 2)}</code> */}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Profile;