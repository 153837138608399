import React from 'react';

import ToTop from '../template/toTop'
// import SearchBar from '../template/searchBar'
// import Carousel from '../template/carousel'

import FilterBar from '../template/filterBar'

// import imgHeader_portal from '../assets/imgHeader_portal.png'
// import imgHeader from '../assets/imgHeader.png'

import $ from 'jquery'

export const IndexPost = props => {

    $(document).ready(() => {
        // $("html, body").animate({ scrollTop: $('#t').offset().top }, 1000);
        $("html, body").animate({ scrollTop: 0 }, 1000);
    })

    return (
        <div>
            <ToTop />
            {/* <SearchBar />
            <Carousel img_1={imgHeader_portal} img_2={imgHeader} />
            <div className="landing_final_div">
                <div className='mt50'></div>
                <button className="bota">COMEÇAR AGORA</button>
            </div> */}
            <div className='mt50'></div>
            <div className="container">
                <div className="content">
                    <div className="landing_comprando" id='t'>
                        <h1>Oportunidades para você AGORA.</h1>
                    </div>
                    <br />
                    <article>
                        <FilterBar categoria={props.match.params.categoria}/>
                    </article>
                </div>
            </div>
            <div className='mt50'></div>
        </div>
    )
}
export default IndexPost