import React from 'react';
import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './template/custom.css'

import Menu from './template/menu'
import Loading from './template/loading'
import Routes from './Routes'
import Footer from './template/footer'
import { useAuth0 } from "./auth0/react-auth0-wrapper";

import { BrowserRouter } from 'react-router-dom'

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return (
      <div className='container-loading text-center'>
    <Loading />
    </div>
    );
  } else {

  return (
    <BrowserRouter>
        <Menu />
        <Routes />
        <Footer />
    </BrowserRouter>
  );
  }
}

export default App;
