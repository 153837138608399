import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'

import Loading from '../template/loading'

import { CardListCarousel } from '../template/cardList'

import { getCategoryName, setAllAuthors } from "../services";

export const OqueCompram = props => {

    const [getAuthorList] = useState(setAllAuthors)

    const getAuthorName = async (author_id) => {
        // console.log('author_id: ', author_id)
        let result = await getAuthorList.then(resp => {
            // console.log('resp: ', resp)
            let resultado

            resp.data.map(prod => {
                if (prod.user_id === author_id) {
                    return resultado = prod.name
                }
                return resultado
            })
            // console.log('resultado: ', resultado)
            return resultado
        })
        return result
    }

    // Pegar as demandas indicadas
    const getIndicatedDemands = async () => {
        // console.log('getCategorieDemands: ', categoryName)
        let data = {
            query: `
                query Categories {
                    demand(limit: 12, order_by: {created_at: desc}) {
                      title
                      bid_price
                      buyer_id
                      category_tag
                      city
                      federated_unit
                      id
                      created_at
                    }
                  }
                `
        }
        const demandList = await axios({
            url: ' https://tocomprando-api.herokuapp.com/v1/graphql',
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
            data: data
        }).then(async (result) => {
            // Varre as demandas encontradas
            // console.log(result)

            result.data.data.demand.map(map => {
                map.categoria = getCategoryName(map.category_tag);
                const nome = getAuthorName(map.buyer_id);
                nome.then(success => {
                    map.buyer_name = success
                })

                // Formato dia/mes/ano
                const data_old = map.created_at.substring(0, 10).split('-')

                return map.created_date = data_old[2] + '/' + data_old[1] + '/' + data_old[0]
            })
            return result
        })
        // Retorna a lista de demandas
        await changeIndicatedList(demandList.data.data.demand)
        changeLoading(false)
        return demandList.data.data.demand
    }

    const [loading, changeLoading] = useState(true)

    useState(getIndicatedDemands)

    const [indicatedList, changeIndicatedList] = useState('')

    if (loading) {
        return (
            <div className='container-loading text-center'>
                <Loading />
            </div>
        )
    } else {
        return (
            <div className="container">
                <div className="content">
                    <div className="landing_comprando_articles">
                        <h1>O que andam comprando?</h1>
                        <div className='landing_funciona'>
                            <p className='texto_padrao'>Você vai se surpreender ao ver a quantidade de pessoas interessadas em comprar algo. De smartphones a até carros, nosso sistema conecta ofertas a produtos, criando matchs que podem virar bons negócios. Quase tudo o que você tem está a venda, só faltava a proposta.</p>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <article>
                        <div className="landing_meio_caixas">
                            {/* {listProd()} */}
                            <CardListCarousel list={indicatedList} />
                        </div>
                    </article>
                    <div className="produtos_botas">
                        <Link to='/anuncios/todos'>
                            <button className="btn btn-default bota">VER TODOS OS PRODUTOS</button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default OqueCompram