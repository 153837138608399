import React, { useState, useEffect } from "react";

import axios from "axios";

// import prodList from "./prodList.json"
import CardList from "./cardList";

import { getCategoryName, getCategory, setAllAuthors } from "../services";

import Loading from "../template/loading";

export const FilterBar = props => {
  // NOTA.:  NÃO USAR PASSAGEM DE PARAMETRO EM USESTATE!
  const [loading, changeLoading] = useState(true);

  const [getAuthorList] = useState(setAllAuthors);

  // Interpretador de categoria baseada na URI
  const setFilterCategory = () => {
    // console.log('setFilterCategory: ', props.categoria)
    switch (props.categoria) {
      case "smartphones":
        return "telephony";
      case "moveis":
        return "furniture";
      case "vestuarios":
        return "clothing";
      case "mobilidade":
        return "mobility";
      case "esportes":
        return "sports";
      case "entretenimento":
        return "entertainment";
      case "eletro-eletronicos":
        return "consumer-electronics";
      case "infantil":
        return "children";
      case "outros":
        return "other";
      default:
        return 0;
    }
  };

  let [categoryName] = useState(setFilterCategory);

  // Consulta baseada na categoria selecionada e tratada
  const getCategorieDemands = async () => {
    // console.log('getCategorieDemands: ', categoryName)
    let data;

    if (categoryName !== 0) {
      data = {
        query: `
                query Categories {
                    demand(where: {category_tag: {_eq: "${categoryName}"}}, order_by: {created_at: desc}) {
                      title
                      bid_price
                      buyer_id
                      category_tag
                      city
                      federated_unit
                      id
                      created_at
                    }
                  }
                `
      };
    } else {
      data = {
        query: `
                query Categories {
                    demand(order_by: {created_at: desc}) {
                      title
                      bid_price
                      buyer_id
                      category_tag
                      city
                      federated_unit
                      id
                      created_at
                    }
                  }
                `
      };
    }
    const demandList = await axios({
      url: " https://tocomprando-api.herokuapp.com/v1/graphql",
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-hasura-admin-secret": "to-vendendo-a-rupi"
      },
      data: data
    }).then(result => {
      // Varre as demandas encontradas
      // console.log(result)
      result.data.data.demand.map(map => {
        map.categoria = getCategoryName(map.category_tag);
        map.uri = getCategory(map.category_tag);
        const nome = getAuthorName(map.buyer_id);
        nome.then(success => {
          map.buyer_name = success;
        });
        // Inserir o nome do autor no objeto
        /*autor.then((result) => {
                    return map.buyer_name = result.name
                })*/
        // Formato dia/mes/ano
        const data_old = map.created_at.substring(0, 10).split("-");

        return (map.created_date =
          data_old[2] + "/" + data_old[1] + "/" + data_old[0]);
      });
      return result;
    });
    // console.log('Ó que interessa: ',demandList.data.data.demand)
    // Retorna a lista de demandas
    return demandList.data.data.demand;
  };

  const getAuthorName = async author_id => {
    // console.log('author_id: ', author_id)
    let result = await getAuthorList.then(resp => {
      // console.log('resp: ', resp)
      let resultado;

      resp.data.map(prod => {
        if (prod.user_id === author_id) {
          return (resultado = prod.name);
        }
        return resultado;
      });
      // console.log('resultado: ', resultado)
      return resultado;
    });
    return result;
  };

  // Lista de Demandas
  const [prodList] = useState(getCategorieDemands);

  // Lista de Demandas Processada
  let [newlist, changeNewList] = useState(
    prodList.then(resp => {
      // console.log('ProdList searchcard: ', resp)
      return resp;
    })
  );
  // let [newlist, changeNewList] = useState(prodList)
  let [category, changeCategory] = useState("");
  let [state, changeState] = useState("Selecionar localização");
  let [price, changePrice] = useState("Ordenar preços");

  // Filtros selecionados
  const searchCards = () => {
    if (
      category !== "Selecionar categoria" &&
      state === "Selecionar localização"
    ) {
      prodList.then(resp => {
        // console.log('ProdList searchcard: ', resp)
        const resp_2 = resp.filter(prod => {
          return prod.category_tag.indexOf(category) !== -1;
        });
        changeOrderValue(resp_2);
        return resp_2;
      });
      return;
    } else if (
      state !== "Selecionar localização" &&
      category === "Selecionar categoria"
    ) {
      prodList.then(resp => {
        // console.log('ProdList searchcard: ', resp)
        const resp_2 = resp.filter(prod => {
          return prod.state.indexOf(category) !== -1;
        });
        changeOrderValue(resp_2);
        return resp_2;
      });
      return;
    } else if (
      category !== "Selecionar categoria" &&
      state !== "Selecionar localização"
    ) {
      prodList.then(resp => {
        // console.log('ProdList searchcard: ', resp)
        const resp_2 = resp.filter(prod => {
          return prod.category_tag.indexOf(category) !== -1;
        });
        const aftresp = resp_2.filter(sub => {
          return sub.federated_unit.indexOf(state) !== -1;
        });
        changeOrderValue(aftresp);
        return aftresp;
      });
      return;
    }
    // console.log('mas ja vai?')
    newlist.then(success => {
      changeOrderValue(success);
    });
    //return changeOrderValue(newlist)
  };

  const changeOrderValue = async resp => {
    let temp = resp === undefined ? newlist : resp;
    // console.log('Temp: ', temp)
    switch (price) {
      case "1": // Maior para menor
        temp.sort((a, b) => {
          let inta = parseInt(a.bid_price);
          let intb = parseInt(b.bid_price);

          return inta > intb ? -1 : inta < intb ? 1 : 0;
        });
        break;
      case "2": // Menor para maior
        temp.sort((a, b) => {
          let inta = parseInt(a.bid_price);
          let intb = parseInt(b.bid_price);

          return inta < intb ? -1 : inta > intb ? 1 : 0;
        });
        break;
      default:
        break;
    }
    const result = await temp;
    // console.log('Temp final: ', result)
    return changeNewList(result);
  };

  const clearCards = () => {
    changeNewList(prodList);
    changeCategory("Selecionar categoria");
    changeState("Selecionar localização");
    changePrice("Ordenar preços");
  };

  const demoAsyncCall = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 3000));
  };

  useEffect(() => {
    //  LOOP INFINITO
    //searchCards()
    /*categoryName.then((result) => {
            result.map(cat => (
                console.log('data: ', cat)
            ))
        })*/
    demoAsyncCall().then(() => changeLoading(false));
  });

  if (loading) {
    return (
      <div className="container-loading text-center">
        <Loading />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 filter-tab text-center content">
          <div className="text-center">
            <p>
              Acione os filtros abaixo para chegar mais rápido ao que você está
              buscando.
            </p>
          </div>
          {categoryName === 0 && (
            <React.Fragment>
              <div className="col-xs-12 col-md-3 col-sm-3 col-lg-3">
                <select
                  name=""
                  id="category"
                  onChange={e => changeCategory(e.target.value)}
                  value={category}
                >
                  <option value="" >
                    Selecionar categoria
                  </option>
                  <option value="consumer-electronics">
                    Eletro-eletrônicos
                  </option>
                  <option value="entertainment">Entretenimento</option>
                  <option value="sports">Esportes</option>
                  <option value="children">Infantil</option>
                  <option value="mobility">Mobilidade</option>
                  <option value="furniture">Móveis</option>
                  <option value="telephony">Smartphones</option>
                  <option value="clothing">Vestuários</option>
                  <option value="other">Outros</option>
                </select>
              </div>

              <div className="col-xs-12 col-md-3 col-sm-3 col-lg-3">
                <select
                  name=""
                  id="state"
                  onChange={e => changeState(e.target.value)}
                  value={state}
                >
                  <option value="Selecionar localização">
                    Selecionar localização
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>

              <div className="col-xs-12 col-md-3 col-sm-3 col-lg-3">
                <select
                  name=""
                  id=""
                  onChange={e => changePrice(e.target.value)}
                  value={price}
                >
                  <option value="Ordenar preços">Ordenar preços</option>
                  <option value="1">Maior para o Menor</option>
                  <option value="2">Menor para o Maior</option>
                </select>
              </div>
              <div className="col-xs-12 col-md-3 col-sm-3 col-lg-3 searcher">
                {/* <div className='col-xs-12 col-md-2 col-sm-2 col-lg-2 searcher'> */}
                <div className="col-xs-6 col-md-6 col-sm-6 col-lg-6 searcher">
                  <button className="bota" onClick={() => searchCards()}>
                    FILTRAR
                  </button>
                </div>
                <div className="col-xs-6 col-md-6 col-sm-6 col-lg-6 searcher">
                  <button className="bota" onClick={() => clearCards()}>
                    LIMPAR
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          {categoryName !== 0 && (
            <React.Fragment>
              <div className="col-xs-12 col-md-4 col-sm-4 col-lg-4">
                <select
                  name=""
                  id="state"
                  onChange={e => changeState(e.target.value)}
                  value={state}
                >
                  <option value="Selecionar localização">
                    Selecionar localização
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>

              <div className="col-xs-12 col-md-4 col-sm-4 col-lg-4">
                <select
                  name=""
                  id=""
                  onChange={e => changePrice(e.target.value)}
                  value={price}
                >
                  <option value="Ordenar preços">Ordenar preços</option>
                  <option value="1">Maior para o Menor</option>
                  <option value="2">Menor para o Maior</option>
                </select>
              </div>
              <div className="col-xs-12 col-md-4 col-sm-4 col-lg-4 searcher">
                {/* <div className='col-xs-12 col-md-2 col-sm-2 col-lg-2 searcher'> */}
                <div className="col-xs-6 col-md-6 col-sm-6 col-lg-6 searcher">
                  <button className="bota" onClick={() => searchCards()}>
                    FILTRAR
                  </button>
                </div>
                <div className="col-xs-6 col-md-6 col-sm-6 col-lg-6 searcher">
                  <button className="bota" onClick={() => clearCards()}>
                    LIMPAR
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <CardList list={newlist} />
      </React.Fragment>
    );
  }
};

export default FilterBar;
