import React, { useState, useEffect } from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {Card, CardDetails} from './card'

// import Loading from '../template/loading'

export const CardList = props => {
    // console.log('Props do cardlist: ', props)
    let [metList, changeMetList] = useState([])

    let parametro
    try {
        parametro = window.sessionStorage.getItem('title').toUpperCase();
    } catch (e) {
        parametro = '';
    }

    let [filter, changeFilter] = useState(parametro === '' ? '' : parametro);

    useEffect(() => {
        try {
            props.list.then(success => {
                changeMetList(success)
                console.log('Carregado com sucesso')
            })
        } catch (e) {
            changeMetList(props.list)
            console.log('Filtrado como array')
        }

    }, [props.list])

    // Renderizar cards
    const renderCards = card => {
        //  console.log('Card: ', card)
        return (
            <Card
                key={card.id}

                id={card.id}
                title={card.title}
                price={card.bid_price}
                author={card.buyer_name}
                state={card.federated_unit}
                city={card.city}
                category_tag={card.category_tag}
                categoria={card.categoria}
                uri={card.uri}
                date={card.created_date}

                buyer_id={card.buyer_id} />
        )
    }

    // Filtrar cards
    const filteredCards = metList.filter(prod => {
        return prod.title.indexOf(filter) !== -1
    })

    return (
        <React.Fragment>
            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 filter-tab text-center content'>
                <div className="text-center mb50 mt50">
                    <p>Busque diretamente o que está procurando</p>
                    <input value={filter} className='text-center' type="text" placeholder='Digite o nome do produto' onChange={(e) => changeFilter(e.target.value.toUpperCase())} />
                </div>
            </div>
            <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 full-wd'>
                {filteredCards.length !== 0 && (
                    <div className="landing_meio_caixas row">
                        {filteredCards.map(card => {
                            return renderCards(card)
                        })}
                    </div>
                )}
                {filteredCards.length === 0 && (
                    <div className="landing_meio_caixas">
                        <h3>Nenhum anúncio encontrado</h3>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export const CardListCarousel = props => {
    // console.log('Lista 2 props: ', props.list)
    // const [loading, changeLoading] = useState(true)

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    // Renderizar cards
    const renderCards = card => {
        //  console.log('Card: ', card)
        return (
            <CardDetails
                key={card.id}

                id={card.id}
                title={card.title}
                price={card.bid_price}
                author={card.buyer_name}
                state={card.federated_unit}
                city={card.city}
                categoria={card.categoria}
                category_tag={card.category_tag}
                date={card.created_date}

                buyer_id={card.buyer_id} />
        )
    }
        return (
            <div className='content mb50'>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    deviceType={props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    className='landing_meio_caixas_carousel'
                >
                    {props.list.map(card => {
                        return renderCards(card)
                    })}
                </Carousel>
            </div>
        )
    }

export const CardListLanding = props => {
    const responsive = {
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: 4,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    const items = props.list

    return (
        <div className='content mb50'>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={1000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {items.map(card => {
                    return <div key={card._id}><Card _id={card._id} title={card.title} price={card.price} author={card.author} city={card.city} category_tag={card.category_tag} date={card.date} description={card.description} /></div>
                })}
            </Carousel>
        </div>
    )
}

export default CardList
