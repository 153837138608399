import React from 'react';

import logoToComprando_footer from '../assets/logoToComprando_footer.svg'
import icon_facebook from '../assets/icon_facebook.svg'
import icon_instagram from '../assets/icon_instagram.svg'

export default props => (
    <footer>
    <div className="container landing_back_footer">
        <div className="content junto_mobile">
            <div className="landing_tudo_mesma">
                <div className="landing_imagem_dindin">
                    <img title=""alt=""src={logoToComprando_footer}/>
                </div>
                <div className="landing_redes_sociais">
                    <img title=""alt=""src={icon_facebook}/>
                    <img title=""alt=""src={icon_instagram}/>
                </div>
                <article>
                    <div className="landing_footer_links">
                        <div className="landing_footer_a1">
                            <h1>Como usar</h1>
                            <a className='bota' href='/termos/anunciando'>Anunciando</a>
                            <a className='bota' href='/perfil'>Quero comprar</a>
                            <a className='bota' href='/perfil'>Quero vender</a>
                            <a className='bota' href='/perfil'>Criar conta</a>
                            <a className='bota' href='/perfil'>Log In</a>
                        </div>
                        <div className="landing_footer_a2">
                            <h1>Institucional</h1>
                            <a className='bota' href='/termos/termos'>Termos de uso</a>
                            <a className='bota' href='/termos/quem-somos'>Sobre nós</a>
                            <a className='bota' href='/termos/como-funciona'>Funcionamento do site</a>
                            <a className='bota' href='/termos/sua-conta'>Sua conta</a>
                            <a className='bota' href='/termos/quem-somos'>Fale conosco</a>
                        </div>
                    </div>
                </article>
            </div>
            <div className="direitos_nid">
                <p> TôComprando®, 2019. Direitos reservados.</p>
                {/* <img title=""alt=""src="images/icon_NiD.svg"/> */}
            </div>
        </div>
    </div>
</footer>
)