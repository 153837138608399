import React from 'react';

import ToTop from '../template/toTop'
import SearchBar from '../template/searchBar'
import Carousel from '../template/carousel'
import ComoFunciona from './comoFunciona'
import OqueCompram from './oqueCompram'
import PrincipaisCategorias from './principaisCategorias'
import EncontroOportunidades from './encontreOportunidades'
import ComecarAgora from './comecarAgora'

import imgHeader_portal from '../assets/imgHeader_portal.png'
import imgHeader from '../assets/imgHeader.png'
import iPhone from '../assets/iPhone.png'

import $ from 'jquery'

export const Landing = props => {

    $(document).ready(() => {
        $("html, body").animate({ scrollTop: 0 }, 1000);
      })
      
    return (
        <div>
            <ToTop />
            <SearchBar />
            <Carousel img_1={imgHeader_portal} img_2={imgHeader} />
            <div className="container landing_chegouahora" id='parte2'>
                <div className="content">
                    <div className="landing_responsivo_caixa">
                        <h1>Chegou a hora de tomar o controle. Afinal, quem compra é que tem o poder.</h1>
                        <p>Agora é você quem diz o que quer comprar e quanto quer pagar. Uma nova forma de fazer compras, onde você valoriza seu dinheiro e encontra vendedores que aceitem sua oferta.</p>
                    </div>
                    <div className="landing_iphone">
                        <img title="" alt="" src={iPhone} />
                    </div>
                </div>
            </div>
            <ComoFunciona />
            <OqueCompram />
            <PrincipaisCategorias />
            <EncontroOportunidades />
            <div className="container landing_back_imagem">
                <div className="content">
                    <h1>“Um jeito muito mais transparente de se comprar e vender.”</h1>
                    <p>––– Rafael Andrade, CEO</p>
                </div>
            </div>
            <ComecarAgora />
        </div>
    )
}

export default Landing
