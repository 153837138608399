import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Button, Modal, ProgressBar } from 'react-bootstrap'

import Camera from '../assets/camera.png'

import IntlCurrencyInput from "react-intl-currency-input"
const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };
  
export const Post = props => {

    const user = props.user.data

    const [title, changeTitle] = useState(window.sessionStorage.getItem('title') === '' ? '' : window.sessionStorage.getItem('title'));
    const [price, changePrice] = useState(window.sessionStorage.getItem('price') === '' ? '' : window.sessionStorage.getItem('price'));

    const [description, changeDescription] = useState('ex: iPhone 8 plus 64GB único dono');

    const [img, changeImg] = useState('');
    // const [reference_images, changeReference_images] = useState('');

    const [category, changeCategory] = useState('consumer-electronics');

    const [progress, changeProgress] = useState();

    const noneFunction = () => {

    }
    // Referencia a imagem a ser enviada
    const imageChange = (dir) => {
        changeImg(dir[0])
        var preview = document.getElementById('capa');
        var file = dir[0];
        var reader = new FileReader();

        reader.onloadend = function () {
            preview.src = reader.result;
            // console.log(preview.src)
        }

        if (file) {
            reader.readAsDataURL(file);

        } else {
            preview.src = "";
        }
    }

    /*const valueChange = (evt) => {
        const financialGoal = (evt.target.validity.valid) ? evt.target.value : price;
        return changePrice(financialGoal)
    }*/

    useEffect(() => {
        /*console.log('Buyer_id: ', user.user_id)
        console.log('City: ', user.user_metadata.city)
        console.log('Federated_unit: ', user.user_metadata.federated_unit)
        console.log('Title: ', title)
        console.log('Category: ', category)
        console.log('Bid_price: ', price)
        console.log('Reference_images: ', reference_images)
        console.log('Description: ', description)*/
    })

    // Upload para o Cloudinary
    const handleDrop = file => {
        // Push all the axios request promise into a single array
        // const uploaders = files.map(file => {
        // Initial FormData
        handleShow()
        changeProgress(30)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "yelnqi4n"); // Replace the preset name with your own
        // formData.append("api_key", "593532268532586"); // Replace API key with your own Cloudinary key
        // formData.append("api_secret", "Udw2HAr_uAmwa7TgYfco6FOoc2g")

        // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
        axios({
            url: "https://api.cloudinary.com/v1_1/tocomprando/image/upload",
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: formData
        }).then(response => {
            changeProgress(60)
            const data = response.data;
            // changeReference_images(data);
            const fileURL = data.secure_url // You should store this URL for future references in your app
            // console.log('Data :', data)
            // console.log(fileURL)
            // Criar demanda
            // console.log('getCategorieDemands: ', categoryName)
            // console.log("Categoria: ",category)
            const demand = {
                query: `
                            mutation {
                                insert_demand(objects: {bid_price: "${price}", buyer_id: "${user.user_id}", category_tag: "${category}", city: "${user.user_metadata.city}", description: "${description}", federated_unit: "${user.user_metadata.federated_unit}", reference_images: {data: [{url: "${fileURL}", position: 1}]}, title: "${title.toUpperCase()}"})
                                {
                                    returning {
                                        id
                                      }
                                }
                            }
                    `
            }
            axios({
                url: 'https://tocomprando-api.herokuapp.com/v1/graphql',
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
                data: demand
            }).then((result) => {
                // Criou a demanda
                console.log('Resultado da demanda: ', result)
                changeProgress(100)
            }).catch(err => {
                console.log(err)
                alert('Erro na criação da demanda. Tente novamente em alguns instantes.')
            })
        }).catch(err => {
            console.log(err)
            alert('Erro no processamento da sua requisição')
        })
        /* });
 
         // Once all the files are uploaded 
         axios.all(uploaders).then(() => {
             // ... perform after upload is successful operation
         });*/
    }

    const stopRefresh = (event) => {
        event.preventDefault()
        handleDrop(img)
    }

    const refreshFunction = () => {
        window.location.reload()
    }

    const priceChange = (event, value, maskedValue) => {
        // event.preventDefault();
        let x = maskedValue.substring(3);
        // console.log(maskedValue);
        // console.log(x)
         // masked value (ex: R$1234,56)
        changePrice(x)
      };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            {/* Modal */}
            <Modal size="md" show={show} onHide={handleClose} aria-labelledby="ModalHeader" style={{ display: "block", opacity: 1 }} centered>
                <Modal.Header>
                    <Modal.Title>Criando demanda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(progress !== 100) && (
                        <React.Fragment>
                            <ProgressBar animated now={progress} />
                        </React.Fragment>
                    )}
                    {(progress === 100) && (
                        <React.Fragment>
                            Sua demanda foi criada com sucesso!
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {(progress === 100) && (
                    <Button variant="primary" onClick={refreshFunction}>
                        Ok
                    </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <form onSubmit={stopRefresh}>
                <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                    <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                        <input type="text" name="" className="form-control form-post" value={title} required="required" placeholder="Título do post (o que você quer comprar)" onChange={(e) => changeTitle(e.target.value)} />
                    </div>

                    <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                        {/* <input type="text" pattern="[0-9]*" name="" className="form-control form-post" value={price} required="required" placeholder="Quanto quer pagar (R$)?" onChange={(e) => valueChange(e)} /> */}
                        <IntlCurrencyInput
                        className="form-control form-post"
                        required="required"
                        placeholder="Quanto quer pagar (R$)?"
                        currency="BRL"
                        config={currencyConfig}
                        onChange={priceChange}
                        />
                    </div>

                    <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                        <input type="text" name="cep" id='cep' value={user.user_metadata === undefined ? '' : user.user_metadata.cep} className="form-control form-post" required="required" placeholder="Insira seu CEP em 'Perfil'" minLength="8" maxLength="8" onChange={() => noneFunction()} />
                    </div>

                    <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                        <input type="text" name="cidade" id="cidade" value={user.user_metadata === undefined ? '' : user.user_metadata.city} className="form-control form-post" required="required" placeholder="Cidade (insira seu CEP em 'Perfil')" onChange={() => noneFunction()} />
                    </div>

                    <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                        <input type="text" name="estado" id="estado" value={user.user_metadata === undefined ? '' : user.user_metadata.federated_unit} className="form-control form-post" required="required" placeholder="Estado (insira seu CEP em 'Perfil')" onChange={() => noneFunction()} />
                    </div>

                    <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12'>
                        <label>
                            Descreva brevemente o que quer comprar:
              </label>
                        <textarea name="message" rows="5" cols="30" value={description} onChange={(e) => changeDescription(e.target.value)}></textarea>
                    </div>
                </div>
                <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6 mt20'>
                    <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 text-center'>
                        <label> Foto princial (obrigatória) </label>
                        <div>
                            <label htmlFor="img-capa" className="btn label-imagem">Selecione uma imagem</label>
                            <input type="file" name="img-capa" id='img-capa' className="form-control" required="required" placeholder="Imagem principal" onChange={(e) => imageChange(e.target.files)} />
                        </div>
                        <img src={Camera} alt='capa' name="capa" id='capa' height="150" width="150" />
                    </div>
                    {/* <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                    <label> Segunda Foto </label>
                    <input type="file" name="segunda" id='segunda' className="form-control mb20" required="required" pattern="" title="" placeholder="Imagem principal" />
                </div>
                <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                    <label> Terceira Foto </label>
                    <input type="file" name="terceira" id='terceira' className="form-control mb20" required="required" pattern="" title="" placeholder="Imagem principal" />
                </div>
                <div className='col-xs-12 col-md-6 col-sm-6 col-lg-6'>
                    <label> Quarta Foto </label>
                    <input type="file" name="quarta" id='quarta' className="form-control mb20" required="required" pattern="" title="" placeholder="Imagem principal" />
                </div> */}
                    <div className='col-xs-12 col-md-12 col-sm-12 col-lg-12 filter-tab'>
                        <select
                            className='categoria-select'
                            name="category"
                            id="category"
                            onChange={(e) => changeCategory(e.target.value)}
                            value={category}
                            required>
                            {/* <option defaultValue disabled={true}>Selecionar categoria</option> */}
                            <option value="consumer-electronics">Eletro-eletrônicos</option>
                            <option value="entertainment">Entretenimento</option>
                            <option value="sports">Esportes</option>
                            <option value="children">Infantil</option>
                            <option value="mobility">Mobilidade</option>
                            <option value="furniture">Móveis</option>
                            <option value="telephony">Smartphones</option>
                            <option value="clothing">Vestuários</option>
                            <option value="other">Outros</option>
                        </select>
                    </div>
                    <div className="postar_btn col-xs-12 col-md-12 col-sm-12 col-lg-12">
                        <button type="submit" className="btn bota pull-right">POSTAR</button>
                    </div>
                </div>
            </form>
        </React.Fragment>

    )
}

export default Post