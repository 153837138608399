import React from 'react';
import $ from 'jquery';

// import './custom.js'

import arrowDown from '../assets/arrowDown.svg'

export const ToTop = props => {
    window.onscroll = function () {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            try {
                document.getElementById("myBtn").style.display = "block";
            }
            catch (e) {
                return;
            }
        } else {
            try {
                document.getElementById("myBtn").style.display = "none";
            } catch (e) {
                return;
            }
        }
    }
    const scrollTop = () => {
        // When the user clicks on the button, scroll to the top of the document
        $("html, body").animate({ scrollTop: 0 }, 1000);
        return false;
    }

    $(document).ready(() => {
        $('#myBtn').click(() => {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        })

    })

    return (
        <button id="myBtn" title="Go to top" onClick={scrollTop}>
            <img className='invert bota' title="" alt="" src={arrowDown} />
        </button>
    )

}

export default ToTop