import React from 'react';

import { SearchBar2 } from '../template/searchBar'

export default props => (
    <div className="container landing_back_roxo">
        <div className="content">
            <div className="landing_paragrafos_tudo">
                <div className="landing_paragrafos">
                    <h1>Encontre oportunidades de venda.</h1>
                    <p>Se você viu alguém comprando o que você esta vendendo, pode comemorar. <br/> Agora você só precisa entrar em contato com o comprador e fechar sua venda, simples assim mesmo.
                    </p>
                    <p>Busque o que estão comprando e faça sua venda.</p>
                </div>
                <div className="landing_buscar">
                    <SearchBar2 />
                </div>
            </div>
        </div>
    </div>
)