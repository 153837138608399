import React from 'react';
import {Switch, Route, Redirect} from 'react-router';

import PrivateRoute from "./PrivateRoute";

import Landing from './landing/landing'
import IndexPost from './indexPost/indexPost'
import Details from './posts/detalhes'
import Profile from './profile/principal'
import Credits from './credits/creditos'
import TermosDeUso from './landing/termosUso';

export default props => (
    // <Switch history={hashHistory}>
    <Switch>
        <Route exact path='/' component={Landing} />
        <Route exact path='/termos/:id' component={TermosDeUso} />
        <Route exact path='/anuncios/:categoria/' component={IndexPost} />
        <Route exact path='/anuncios/:category/detalhes/:id' component={Details} />
        <PrivateRoute path="/perfil" component={Profile} />
        {/* <PrivateRoute path="/creditos/:sub" component={Credits} /> */}
        <PrivateRoute path="/creditos" component={Credits} />
        <Redirect from='*' to='/' />
    </Switch>
)