import React from 'react';
import { Link } from 'react-router-dom'

import { useAuth0 } from "../auth0/react-auth0-wrapper";

import icon_busca from '../assets/icon_busca.svg'
import icon_match from '../assets/icon_match.svg'
import icon_conversa from '../assets/icon_conversa.svg'
import icon_compra from '../assets/icon_compra.svg'
import arrowDown from '../assets/arrowDown.svg'

export const ComoFunciona = props => {

    const { isAuthenticated } = useAuth0();


    return (
        <div className="container landing_funciona_back">
            <div className="content">
                <div className="landing_funciona">
                    <h1>Como funciona?</h1>
                    <p className="tagline">Ao contrário dos sites de compra e venda de produtos, queremos que você faça o caminho inverso. É um processo muito simples, onde as cartas estão na mesa desde o início para que os negócios sejam fechados da forma mais rápida e confiável possível. Sem atravessadores e despesas extras.</p>
                </div>
                <div className="landing_tudo">
                    <div className="landing_capsulass">
                        <div className="landing_capsula">
                            <img title="" alt="" src={icon_busca} />
                            <div className="landing_article">
                                {/* <p className="fl_left">1</p> */}
                                Você posta O QUE QUER comprar e quanto está disposto a pagar! Daí é só esperar....Você diz o que quer comprar e quanto pretende gastar.
                            </div>
                        </div>
                        <div className="landing_capsula">
                            <img title="" alt="" src={icon_match} />
                            <div className="landing_article">
                                {/* <p className="fl_right">2</p> */}
                                Quando alguém quiser vender o que você quer comprar e aceitar seu preço, liberamos acesso para negociarem.
                            </div>
                        </div>
                        <div className="landing_capsula">
                            <img title="" alt="" src={icon_conversa} />
                            <div className="landing_article">
                                {/* <p className="fl_left">3</p> */}
                                Aqui é assim, quem quer comprar tem a comodidade de receber ofertas, ao invés de procurar por ai!
                            </div>
                        </div>
                        <div className="landing_capsula">
                            <img title="" alt="" src={icon_compra} />
                            <div className="landing_article">
                                {/* <p className="fl_right">4</p> */}
                                Simples, prático e eficiente. Te desejamos bons negócios!
                            </div>
                        </div>
                    </div>
                    <div className="landing_final_div">
                        <img title="" alt="" src={arrowDown} />
                        {isAuthenticated && (
                            <Link to='/perfil'>
                                <button className="bota">COMEÇAR AGORA</button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComoFunciona