import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";

import { getCategoryName, setAllAuthors, removeDemand } from "../services";

import Loading from "../template/loading";

export const ToComprando = (props) => {
  const user = props.user.data;

  const getToComprando = async () => {
    const data = {
      query: `
            query Historic {
                demand(where: {buyer_id: {_eq: "${user.user_id}"}}, order_by: {updated_at: desc}) {
                        id
                        title
                        description
                        bid_price
                        category_tag
                        created_at
                }
            }
            `,
    };
    await axios({
      url: " https://tocomprando-api.herokuapp.com/v1/graphql",
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-hasura-admin-secret": "to-vendendo-a-rupi",
      },
      data: data,
    }).then((result) => {
      // console.log(result)
      result.data.data.demand.map((res) => {
        res.categoria = getCategoryName(res.category_tag);
        const data_old = res.created_at.substring(0, 10).split("-");
        return (res.created_date =
          data_old[2] + "/" + data_old[1] + "/" + data_old[0]);
      });
      // console.log(result.data.data.offer)

      changeToComprando(result.data.data.demand);
    });
  };

  //   useState(getToComprando);

  const [toComprando, changeToComprando] = useState(getToComprando);
  const [id, changeId] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const redirect = (target) => {
    window.location.href = "/anuncios/todos/detalhes/" + target;
  };

  const [loading, changeLoading] = useState(true);

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };

  useEffect(() => {
    // console.log(oferta)
    demoAsyncCall().then(() => changeLoading(false));
  });

  if (loading) {
    return (
      <div className="container-loading text-center">
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          aria-labelledby="ModalHeader"
          style={{ display: "block", opacity: 1 }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Removendo demanda</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                Deseja remover sua demanda?
          </Modal.Body>
          <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={() => removeDemand(id)}>
                Sim
              </Button>
          </Modal.Footer>
        </Modal>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="table-jumbotron">
            {toComprando.length !== 0 && (
              <Table className="table table-hover table-status table-profile">
                <Thead>
                  <Tr>
                    <Th>
                      <label htmlFor="">Descrição</label>
                    </Th>
                    <Th>
                      <label htmlFor="">Valor</label>
                    </Th>
                    <Th>
                      <label htmlFor="">Categoria</label>
                    </Th>
                    <Th>
                      <label htmlFor="">Data</label>
                    </Th>
                    <Th>
                      <label htmlFor="">Ações</label>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {toComprando.map((result) => (
                    <Tr key={result.id}>
                      <Td>
                        <b>{result.title}</b>
                      </Td>
                      <Td>
                        <b>R$ {result.bid_price}</b>
                      </Td>
                      <Td>
                        <b>{result.categoria}</b>
                      </Td>
                      <Td>
                        <b>{result.created_date}</b>
                      </Td>
                      <Td>
                        <Link to={`/anuncios/todos/detalhes/${result.id}`}>
                          <button
                            className="bota"
                            onClick={() => redirect(result.id)}
                          >
                            detalhes
                          </button>
                        </Link>
                        <button
                          className="bota excluir"
                          onClick={() => {
                            changeId(result.id);
                            handleShow();
                          }}
                        >
                          excluir
                        </button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            {toComprando.length === 0 && (
              <p className="text-center">
                <b>Não foram criadas ofertas</b>
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
};

export const ToVendendo = (props) => {
  const user = props.user.data;

  const [getAuthorList] = useState(setAllAuthors);

  const getToVendendo = async () => {
    const data = {
      query: `
            query Historic {
                    offer(where: {seller_id: {_eq: "${user.user_id}"}}) {
                    created_at
                    id
                demand {
                    id
                    title
                    description
                    bid_price
                    category_tag
                    buyer_id
                }
            }
        }
    `,
    };
    await axios({
      url: " https://tocomprando-api.herokuapp.com/v1/graphql",
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-hasura-admin-secret": "to-vendendo-a-rupi",
      },
      data: data,
    }).then((result) => {
      result.data.data.offer.map((res) => {
        res.demand.categoria = getCategoryName(res.demand.category_tag);
        const data_old = res.created_at.substring(0, 10).split("-");
        res.created_date = data_old[2] + "/" + data_old[1] + "/" + data_old[0];

        const nome = getAuthorInfos(res.demand.buyer_id);
        return nome.then((success) => {
          res.author = success;
        });
      });
      // console.log(result.data.data.offer)
      changeToVendendo(result.data.data.offer);
    });
  };

  const getAuthorInfos = async (author_id) => {
    // console.log('author_id: ', author_id)
    let result = await getAuthorList.then((resp) => {
      // console.log('resp: ', resp)
      let resultado;

      resp.data.map((prod) => {
        if (prod.user_id === author_id) {
          return (resultado = prod);
        }
        return resultado;
      });
      // console.log('resultado: ', resultado)
      return resultado;
    });
    return result;
  };

  useState(getToVendendo);

  const [toVendendo, changeToVendendo] = useState("");

  const redirect = (target) => {
    window.location.href = "/anuncios/todos/detalhes/" + target;
  };

  const [loading, changeLoading] = useState(true);

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };

  useEffect(() => {
    // console.log('ttt: ', toVendendo)
    demoAsyncCall().then(() => changeLoading(false));
  });

  if (loading) {
    return (
      <div className="container-loading text-center">
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="table-jumbotron">
        {toVendendo.length !== 0 && (
          <Table className="table table-hover table-status table-profile">
            <Thead>
              <Tr>
                <Th>
                  <label htmlFor="">Descrição</label>
                </Th>
                <Th>
                  <label htmlFor="">Valor</label>
                </Th>
                <Th>
                  <label htmlFor="">Categoria</label>
                </Th>
                <Th>
                  <label htmlFor="">Nome</label>
                </Th>
                <Th>
                  <label htmlFor="">Contato</label>
                </Th>
                <Th>
                  <label htmlFor="">Data</label>
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {toVendendo.map((result) => (
                <Tr key={result.id}>
                  <Td>
                    <b>{result.demand.title}</b>
                  </Td>
                  <Td>
                    <b>R$ {result.demand.bid_price}</b>
                  </Td>
                  <Td>
                    <b>{result.demand.categoria}</b>
                  </Td>
                  <Td>
                    <b>{result.author.name}</b>
                  </Td>
                  <Td>
                    <b>{result.author.user_metadata.phone}</b>
                  </Td>
                  <Td>
                    <b>{result.created_date}</b>
                  </Td>
                  <Td>
                    <Link to={`/anuncios/todos/detalhes/${result.demand.id}`}>
                      <button
                        className="bota"
                        onClick={() => redirect(result.demand.id)}
                      >
                        detalhes
                      </button>
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {toVendendo.length === 0 && (
          <p className="text-center">
            <b>Não foram realizadas ofertas</b>
          </p>
        )}
      </div>
    );
  }
};

export default { ToComprando, ToVendendo };
