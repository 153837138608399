import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Loading from '../template/loading'

export const MeusCreditos = props => {
    const user = props.user.data
    // console.log(user)

    const goTo = {
        // pathname: `/creditos/${user.user_id}`,
        pathname: `/creditos`,
        param: user
    }

    const getCreditos = async () => {
        const data = {
            query: `
            query Credits {
                customer(where: {id: {_eq: "${user.user_id}"}}) {
                    credit
              }
            }
            `
        }
        await axios({
            url: 'https://tocomprando-api.herokuapp.com/v1/graphql',
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
            data: data
        }).then((result) => {
            // console.log(result.data.data.customer)
            changeCreditos(result.data.data.customer[0].credit)
        })
        //return author
    }

    const getOferta = async () => {
        const data = {
            query: `
            query Offer {
                credit_purchase(where: {customer_id: {_eq: "${user.user_id}"}}, limit: 1, order_by: {created_at: desc}) {
                    credit
                    amount
              }
            }
            `
        }
        await axios({
            url: 'https://tocomprando-api.herokuapp.com/v1/graphql',
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
            data: data
        }).then((result) => {
            // console.log(result.data.data)
            changeOferta(result.data.data.credit_purchase)
        })
    }

    const getHistorico = async () => {
        const data = {
            query: `
            query Historic {
                credit_purchase(where: {customer_id: {_eq: "${user.user_id}"}}) {
                    credit
                    credit_pack_tag
                    amount
                    created_at
                    id
              }
            }
            `
        }
        await axios({
            url: 'https://tocomprando-api.herokuapp.com/v1/graphql',
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'X-hasura-admin-secret': 'to-vendendo-a-rupi' },
            data: data
        }).then((result) => {
            // console.log('a: ',result)
            result.data.data.credit_purchase.map(map => {
                const data_old = map.created_at.substring(0, 10).split('-')

                return map.created_date = data_old[2] + '/' + data_old[1] + '/' + data_old[0]
            })
            changeHistorico(result.data.data.credit_purchase)
        })
    }

    useState(getCreditos)
    useState(getOferta)
    useState(getHistorico)

    const [creditos, changeCreditos] = useState('')
    const [oferta, changeOferta] = useState('')
    const [historico, changeHistorico] = useState('')

    const [loading, changeLoading] = useState(true)

    const demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 3000));
    }

    useEffect(() => {
        // console.log(oferta)
        demoAsyncCall().then(() => changeLoading(false));
    })

    if (loading) {
        return (
            <div className='container-loading text-center'>
                <Loading />
            </div>
        )
    } else {
        return (
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 text-center'>
                    <h1>Créditos Restantes</h1>
                    <p>Você ainda tem</p>
                    <div className='circle'>{creditos}</div>
                    <br />
                    <p>OFERTAS</p>
                    <p>disponíveis em seu plano atual. A cada oferta, você gasta 1 crédito.</p>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 text-center'>
                    <h1>Créditos Adquiridos</h1>
                    <div className='bly'>
                        <p>Você adquiriu</p>
                        {oferta.length !== 0 && (
                            <React.Fragment>
                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center'>
                                    <div className='square'>
                                        {oferta[0].credit}
                                    </div>
                                </div>

                                <br />
                                <p>OFERTAS</p>
                                <div className='losang'>R$ {oferta[0].amount / 100}</div>
                            </React.Fragment>
                        )}
                        {oferta.length === 0 && (
                            <React.Fragment>
                                <div className='square'>
                                    0
                        </div>
                                <br />
                                <p>OFERTAS</p>
                                <div className='losang'>R$ 0</div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                    <h1>Histórico de Compras</h1>
                    <div className='bly'>
                        <div className='table-historico text-center'>
                            {historico.length === 0 && (
                                <p className='text-center'>
                                    <b>
                                        Não foram realizadas compras
                            </b>
                                </p>
                            )}
                            {historico.length !== 0 && (
                                <React.Fragment>
                                    <Table className='table table-hover table-status table-profile'>
                                        <Thead>
                                            <Tr>
                                                <Th><label htmlFor="">Valor</label></Th>
                                                <Th><label htmlFor="">Ofertas</label></Th>
                                                <Th><label htmlFor="">Data da Compra</label></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {historico.map(result => (
                                                <Tr key={result.id}>
                                                    <Td>
                                                        <b>R$ {result.amount / 100}</b>
                                                    </Td>
                                                    <Td>
                                                        <b>{result.credit}</b>
                                                    </Td>
                                                    <Td>
                                                        <b>{result.created_date}</b>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <div className="postar_btn col-xs-12 col-md-12 col-sm-12 col-lg-12">
                    <Link to={goTo}>
                        <button className="btn bota">INCLUIR CRÉDITOS</button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default MeusCreditos