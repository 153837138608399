import React, { useState, useEffect } from "react";
import axios from "axios";

import { useAuth0 } from "../auth0/react-auth0-wrapper";

import DetalhesHeader from "./detalhesHeader";
import DetalhesBody from "./detalhesBody";
import DetalhesTable from "./detalhesTable";

import { CardListCarousel } from "../template/cardList";

import { getCategoryName, setAllAuthors } from "../services";

import arrowDOWN from "../assets/arrowDown.svg";

import $ from "jquery";

import Loading from "../template/loading";

import { Button, Modal } from "react-bootstrap";

export const Detalhes = props => {
  //const data = props.location.param
  //console.log(props.match.params) // Pega o _id pela URI
  //console.log(props.location.param) // Pega o objeto inteiro so se vier de outro canto anteriormente
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  // ver cmo o is loggin

  // console.log(user)
  // NOTA.:  NÃO USAR PASSAGEM DE PARAMETRO EM USESTATE!
  const [loading, changeLoading] = useState(true);

  const [getAuthorList] = useState(setAllAuthors);

  // Interpretador de categoria baseada na URI
  const setFilterCategory = () => {
    // console.log('setFilterCategory: ', props.categoria)
    switch (props.categoria) {
      case "smartphones":
        return "telephony";
      case "móveis":
        return "furniture";
      case "vestuários":
        return "clothing";
      case "mobilidade":
        return "mobility";
      case "esportes":
        return "sports";
      case "entretenimento":
        return "entertainment";
      case "eletro-eletrônicos":
        return "consumer-electronics";
      case "infantil":
        return "children";
      case "outros":
        return "other";
      default:
        return 0;
    }
  };

  useState(setFilterCategory);

  // Consulta baseada na categoria selecionada e tratada
  const getCategorieDemands = async () => {
    // console.log('getCategorieDemands: ', categoryName)
    const data = {
      query: `
                    query Categories {
                        demand(where: {id: {_eq: "${props.match.params.id}"}}) {
                          title
                          bid_price
                          buyer_id
                          category_tag
                          city
                          federated_unit
                          id
                          created_at
                          description
                          reference_images{
                            url
                          }
                        }
                      }
                    `
    };
    const demandList = await axios({
      url: " https://tocomprando-api.herokuapp.com/v1/graphql",
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-hasura-admin-secret": "to-vendendo-a-rupi"
      },
      data: data
    }).then(result => {
      // Varre as demandas encontradas
      result.data.data.demand.map(map => {
        map.categoria = getCategoryName(map.category_tag);
        const nome = getAuthorName(map.buyer_id);
        nome.then(success => {
          map.buyer_name = success;
        });
        // Pega uma imagem
        map.imagem = map.reference_images[0].url;
        //console.log(map.reference_images[0].url)
        // Inserir o nome do autor no objeto
        /*autor.then((result) => {
                    return map.buyer_name = result.name
                })*/
        // Formato dia/mes/ano
        const data_old = map.created_at.substring(0, 10).split("-");

        return (map.created_date =
          data_old[2] + "/" + data_old[1] + "/" + data_old[0]);
      });
      return result;
    });
    // Retorna a lista de demandas
    changeInfos(demandList.data.data.demand);
    return demandList.data.data.demand;
  };

  const getAuthorName = async author_id => {
    // console.log('author_id: ', author_id)
    let result = await getAuthorList.then(resp => {
    //   console.log('resp: ', resp)
      let resultado;

      resp.data.map(prod => {
        if (prod.user_id === author_id) {
          return (resultado = prod.name);
        }
        return resultado;
      });
      // console.log('resultado: ', resultado)
      return resultado;
    });
    return result;
  };

  // Pegar as demandas indicadas
  const getIndicatedDemands = async () => {
    // console.log('getCategorieDemands: ', categoryName)
    let data = {
      query: `
                query Categories {
                    demand(limit: 8, order_by: {created_at: asc}) {
                      title
                      bid_price
                      buyer_id
                      category_tag
                      city
                      federated_unit
                      id
                      created_at
                    }
                  }
                `
    };
    const demandList = await axios({
      url: " https://tocomprando-api.herokuapp.com/v1/graphql",
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-hasura-admin-secret": "to-vendendo-a-rupi"
      },
      data: data
    }).then(result => {
      // Varre as demandas encontradas
      result.data.data.demand.map(map => {
        map.categoria = getCategoryName(map.category_tag);
        const nome = getAuthorName(map.buyer_id);
        nome.then(success => {
          map.buyer_name = success;
        });
        // Formato dia/mes/ano
        const data_old = map.created_at.substring(0, 10).split("-");

        return (map.created_date =
          data_old[2] + "/" + data_old[1] + "/" + data_old[0]);
      });
      return result;
    });
    // Retorna a lista de demandas
    changeIndicatedList(demandList.data.data.demand);
    return demandList.data.data.demand;
  };

  const getInfos = async () => {
    // console.log('Infos: ',infos[0])
    // Gerar credenciais Auth0
    await axios({
      url: "https://tocomprando.auth0.com/oauth/token",
      method: "POST",
      headers: { "Content-type": "application/json" },
      data:
        '{"client_id":"QrFMwN1MLOlkFjxXejbg790E0z1aPUM4","client_secret":"S6vz00iM6LIxEiFJlQcuSafpn4DJHOVMETI83q4TadUEOCIywqpErZbAWY1B2TDB","audience":"https://tocomprando.auth0.com/api/v2/","grant_type":"client_credentials"}'
    }).then(async client_credentials => {
      // console.log(client_credentials.data)
      await axios({
        url: `https://tocomprando.auth0.com/api/v2/users/${infos[0].buyer_id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          authorization: `${client_credentials.data.token_type} ${client_credentials.data.access_token}`
        }
      }).then(result => {
        // console.log(result.data.user_metadata.phone)
        // refresh()
        changeCel(result.data.user_metadata.phone);
        changeAutor(result.data);
        handleClose();
        const demand = {
          query: `
                            mutation {
                                insert_offer(objects: {demand_id: "${infos[0].id}", seller_id: "${user.sub}"})
                                {
                                    returning {
                                        demand {
                                            buyer_id
                                        }
                                      }
                                }
                            }
                    `
        };
        axios({
          url: " https://tocomprando-api.herokuapp.com/v1/graphql",
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-hasura-admin-secret": "to-vendendo-a-rupi"
          },
          data: demand
        })
          .then(result => {
            // Criou a demanda
            console.log("Resultado da cria: ", result);
            handleShow();
          })
          .catch(err => {
            console.log(err);
            alert(
              "Erro na criação da demanda. Tente novamente em alguns instantes."
            );
          });
        handleShowData();
      });
    });
    //return author
  };

  const [infos, changeInfos] = useState("");
  const [indicatedList, changeIndicatedList] = useState("");

  useState(getCategorieDemands);
  useState(getIndicatedDemands);

  $(document).ready(() => {
    // $("html, body").animate({ scrollTop: $('#t').offset().top }, 1000);
    $("html, body").animate({ scrollTop: 0 }, 1000);
  });

  const refresh = () => {
    window.location.reload();
  };

  const [autor, changeAutor] = useState("");
  const [cel, changeCel] = useState("");

  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseData = () => setShowData(false);
  const handleShowData = () => setShowData(true);

  const demoAsyncCall = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 3000));
  };

  useEffect(() => {
    demoAsyncCall().then(() => changeLoading(false));
    // console.log(infos[0])
  });

  if (loading) {
    return (
      <div className="container-loading text-center">
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="detalhes-page">
        <div className="content">
          {/* Modal */}
          <Modal
            size="md"
            show={show}
            onHide={handleClose}
            aria-labelledby="ModalHeader"
            style={{ display: "block", opacity: 1 }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Usar créditos?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Será utilizado 1 crédito para obtenção de dados do comprador
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Não
              </Button>
              <Button variant="primary" onClick={getInfos}>
                QUERO!
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Modal Data*/}
          <Modal
            size="md"
            show={showData}
            onHide={handleCloseData}
            aria-labelledby="ModalHeader"
            style={{ display: "block", opacity: 1 }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Parabéns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Aqui estão as informações do vendedor. Mas não se preocupe, elas
                ficarão salvas no seu Perfil para ver quando quiser!
              </p>
              <p>
                Nome: <b>{autor.name}</b>
              </p>
              <p>
                Telefone: <b>{cel}</b>
              </p>
              <p>
                E-mail: <b>{autor.email}</b>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={refresh}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>

          <DetalhesHeader title={infos[0].title} price={infos[0].bid_price} />

          <DetalhesBody
            images={infos[0].imagem}
            author={infos[0].buyer_name}
            state={infos[0].federated_unit}
            city={infos[0].city}
            category={infos[0].categoria}
            date={infos[0].created_date}
            description={infos[0].description}
          />

          {isAuthenticated && (
            <React.Fragment>
              {infos[0].buyer_id === user.sub && (
                <DetalhesTable
                  id={props.match.params.id}
                  authors={getAuthorList}
                />
              )}
            </React.Fragment>
          )}

          <div className="text-center">
            <img
              className="seta-down mt20 mb20"
              title=""
              alt=""
              id=""
              src={arrowDOWN}
            />
          </div>
          <div className="produtos_detalhes">
            {isAuthenticated && (
              <React.Fragment>
                {infos[0].buyer_id !== user.sub && (
                  <button className="btn btn-default bota" onClick={handleShow}>
                    TENHO O PRODUTO E QUERO VENDER
                  </button>
                )}
              </React.Fragment>
            )}
            {!isAuthenticated && (
              <button
                className="btn btn-default bota"
                onClick={() => loginWithRedirect({})}
              >
                TENHO O PRODUTO E QUERO VENDER
              </button>
            )}
          </div>

          <div className="content mt20">
            <label>
              Pessoas que buscaram esse produto, também se interessaram:
            </label>
          </div>
          <CardListCarousel list={indicatedList} />
        </div>
      </div>
    );
  }
};

export default Detalhes;
